const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [],
  },
  {
    id: 'members',
    title: 'Members',
    messageId: 'sidebar.members',
    icon: 'dashboard',
    type: 'collapse',
    children: [
      {
        id: 'edit-members',
        title: 'Edit Members',
        messageId: 'sidebar.edit.members',
        type: 'item',
        url: '/editmember',
      },
      {
        id: 'create-new-customer',
        title: 'Create New Customer',
        messageId: 'sidebar.create.members',
        type: 'item',
        url: '/create-new-customer',
      },
      {
        id: 'new-customers',
        title: 'sidebar.member.newCustomers',
        messageId: 'sidebar.member.newCustomers',
        type: 'item',
        url: '/new-customers',
      },
    ],
  },
  {
    id: 'reach',
    title: 'sidebar.reach',
    messageId: 'sidebar.reach',
    icon: 'receipt',
    type: 'collapse',
    children: [
      {
        id: 'reachcustomers',
        title: 'sidebar.reports.reachcustomers',
        messageId: 'sidebar.reports.reachcustomers',
        type: 'item',
        url: '/reach-customers',
      }
    ]
  },
  {
    id: 'reports',
    title: 'sidebar.reports',
    messageId: 'sidebar.reports',
    icon: 'receipt',
    type: 'collapse',
    children: [
      {
        id: 'quick-stats',
        title: 'sidebar.reports.quickstats',
        messageId: 'sidebar.reports.quickstats',
        type: 'item',
        url: '/quickstats',
      },
      {
        id: 'enrichment-stats',
        title: 'sidebar.reports.enrichment_stats',
        messageId: 'sidebar.reports.enrichment_stats',
        type: 'item',
        url: '/enrichment_stats',
      },
      {
        id: 'count',
        title: 'sidebar.reports.counts',
        messageId: 'sidebar.reports.counts',
        type: 'item',
        url: '/counts',
      },
      {
        id: 'tracker',
        title: 'sidebar.reports.tracker',
        messageId: 'sidebar.reports.tracker',
        type: 'item',
        url: '/tracker',
      },
      {
        id: 'latestcustomer',
        title: 'sidebar.reports.latestcustomer',
        messageId: 'sidebar.reports.latestcustomer',
        type: 'item',
        url: '/latest_customer',
      },
      {
        id: 'stripestats',
        title: 'sidebar.reports.stripe.stats',
        messageId: 'sidebar.reports.stripe.stats',
        type: 'item',
        url: '/stripe-stats',
      },
      {
        id: 'customer-unlocks-report',
        title: 'sidebar.customer.unlocksreport',
        messageId: 'sidebar.customer.unlocksreport',
        type: 'item',
        url: '/customer-unlocks-report',
      },
      {
        id: 'account-standing',
        title: 'sidebar.customer.accountstanding',
        messageId: 'sidebar.customer.accountstanding',
        type: 'item',
        url: '/account-standing',
      },
      {
        id: 'company',
        title: 'sidebar.report.company_domain_verifier',
        messageId: 'sidebar.report.company_domain_verifier',
        type: 'item',
        url: '/company-list',
      }
    ],
  },  
  {
    id: 'data-entry',
    title: 'sidebar.edit.data.entry',
    messageId: 'sidebar.edit.data.entry',
    icon: 'computer',
    type: 'collapse',
    children: [
      {
        id: 'create-edit-company',
        title: 'sidebar.add-edit-company',
        messageId: 'sidebar.add-edit-company',
        type: 'item',
        url: '/dataentry/createEditCompany',
      },
      {
        id: 'press_news_funding_financing_entry',
        title: 'sidebar.edit.data.press_news_funding_financing_entry',
        messageId: 'sidebar.edit.data.press_news_funding_financing_entry', 
        type: 'item',
        url: '/dataentry/press_news_funding_financing_entry',
      },
      {
        id: 'read-press',
        title: 'sidebar.read-press',
        messageId: 'sidebar.read-press',
        type: 'item',
        url: '/dataentry/createPress', //press_staged true false
      },
      // {
      //   id: 'smaller-companies-update',
      //   title: 'sidebar.update_old_companies',
      //   messageId: 'sidebar.update_old_companies',
      //   type: 'item',
      //   url: '/dataentry/update_old_companies', //update_old_companies
      // },
      {
        id: 'add-staged-press',
        title: 'sidebar.add-staged-press',
        messageId: 'sidebar.add-staged-press',
        type: 'item',
        url: '/dataentry/addStagedPress',
      },
      {
        id: 'view-staged-press',
        title: 'sidebar.view-staged-press',
        messageId: 'sidebar.view-staged-press',
        type: 'item',
        url: '/dataentry/viewStagedPress',
      },
      // {
      //   id: 'update-employee-size',
      //   title: 'sidebar.update-employee-size',
      //   messageId: 'sidebar.update-employee-size',
      //   type: 'item',
      //   url: '/dataentry/update-employee-size',
      // },
       {
         id: 'company-for-today-press',
         title: 'sidebar.company-for-today-press',
         messageId: 'sidebar.company-for-today-press',
         type: 'item',
         url: '/dataentry/searchNewsletterCompany',
       },
      {
        id: 'add-inside-product',
        title: 'sidebar.edit.addinside.product',
        messageId: 'sidebar.edit.addinside.product',
        type: 'item',
        url: '/add-inside-product',
      },
      {
        id: 'data-mismatch',
        title: 'sidebar.data-mismatch',
        messageId: 'sidebar.data-mismatch',
        type: 'item',
        url: '/data-mismatch',
      },
      // {
      //   id: 'employee-data-mismatch',
      //   title: 'sidebar.employee-data-mismatch',
      //   messageId: 'sidebar.employee-data-mismatch',
      //   type: 'item',
      //   url: '/employee-data-mismatch',
      // },
      {
        id: 'outside-technology',
        title: 'sidebar.add-outside-technology',
        messageId: 'sidebar.add-outside-technology',
        type: 'item',
        url: '/outside-technology',
      }
    ],
  }, 
  {
    id: 'data-entry-2',
    title: 'sidebar.edit.data.entry',
    messageId: 'sidebar.edit.data.entry2',
    icon: 'computer',
    type: 'collapse',
    children: [
      {
        id: 'press_news_entry_2',
        title: 'sidebar.edit.data.press_news_entry_2',
        messageId: 'sidebar.edit.data.press_news_entry_2', 
        type: 'item',
        url: '/press_news_entry',
      },      
      {
        id: 'press_news_funding_insert_company',
        title: 'sidebar.edit.data.press_news_funding_insert_company',
        messageId: 'sidebar.edit.data.press_news_funding_insert_company', 
        type: 'item',
        url: '/press_news_funding_insert_company',
      },
      {
        id: 'create-edit-company',
        title: 'sidebar.add-edit-company',
        messageId: 'sidebar.add-edit-company',
        type: 'item',
        url: '/dataentry2/createEditCompany',
      },
      {
        id: 'read-press',
        title: 'sidebar.read-press',
        messageId: 'sidebar.read-press',
        type: 'item',
        url: '/dataentry2/createPress', //press_staged true false
      },
      {
        id: 'add-inside-product',
        title: 'sidebar.edit.addinside.product',
        messageId: 'sidebar.edit.addinside.product',
        type: 'item',
        url: '/add-inside-product',
      },
      {
        id: 'data-mismatch',
        title: 'sidebar.data-mismatch',
        messageId: 'sidebar.data-mismatch',
        type: 'item',
        url: '/dataentry2/data-mismatch',
      },
      // {
      //   id: 'employee-data-mismatch',
      //   title: 'sidebar.employee-data-mismatch',
      //   messageId: 'sidebar.employee-data-mismatch',
      //   type: 'item',
      //   url: '/employee-data-mismatch',
      // },
      {
        id: 'outside-technology',
        title: 'sidebar.add-outside-technology',
        messageId: 'sidebar.add-outside-technology',
        type: 'item',
        url: '/outside-technology',
      }
    ],
  },
  
  {
    id: 'approvals',
    title: 'sidebar.approvals',
    messageId: 'sidebar.approvals',
    icon: 'thumb_up',
    type: 'collapse',
    children: [
      {
        id: 'api-requests',
        title: 'sidebar.apirequests.managekeys',
        messageId: 'sidebar.apirequests.managekeys',
        type: 'item',
        url: '/api-requests',
      },
      {
        id: 'approve',
        title: 'sidebar.approval.approve',
        messageId: 'sidebar.approval.approve',
        type: 'item',
        url: '/approve',
      },
      {
        id: 'enrichment_approval',
        title: 'sidebar.apirequests.enrichment_approval',
        messageId: 'sidebar.apirequests.enrichment_approval',
        type: 'item',
        url: '/enrichment_approve',
      },
      {
        id: 'restore-customer-cart',
        title: 'sidebar.customercart',
        messageId: 'sidebar.customercart',
        type: 'item',
        url: '/restore-customer-cart',
      },
    ],
  },
  // {
  //   id: 'gmail-main',
  //   title: 'sidebar.gmail',
  //   messageId: 'sidebar.gmail',
  //   icon: 'mail',
  //   type: 'collapse',
  //   children: [
  //     {
  //       id: 'account_linking',
  //       title: 'sidebar.account_linking',
  //       messageId: 'sidebar.account_linking',
  //       type: 'item',
  //       url: '/gmail',
  //     },
  //     {
  //       id: 'gmail_drafted_records',
  //       title: 'sidebar.gmail_drafted_records',
  //       messageId: 'sidebar.gmail_drafted_records',
  //       type: 'item',
  //       url: '/gmail_drafted_records',
  //     },
  //     {
  //       id: 'gmail_csv',
  //       title: 'sidebar.gmail_csv',
  //       messageId: 'sidebar.gmail_csv',
  //       type: 'item',
  //       url: '/gmail_csv',
  //     },
  //   ]
  // },
  {
    id: 'suspicious-alerts',
    title: 'sidebar.suspicious-alerts',
    messageId: 'sidebar.suspicious-alerts',
    icon: 'notifications',
    type: 'collapse',
    children: [
      {
        id: 'customer-abuse-calculated',
        title: 'sidebar.customer_abuse_calculated',
        messageId: 'sidebar.customer_abuse_calculated',
        type: 'item',
        url: '/top_abusers',
      },
      {
        id: 'alerts',
        title: 'sidebar.customer_alerts',
        messageId: 'sidebar.customer_alerts',
        // icon: 'notifications',
        count: 0,
        type: 'item',
        url: '/alerts',
      },
      {
        id: 'customer-abuse',
        title: 'sidebar.reports.customer.abuse',
        messageId: 'sidebar.reports.customer.abuse',
        type: 'item',
        url: '/customer/abuse',
      },
      {
        id: 'ban-ip',
        title: 'sidebar.reports.ban_ip',
        messageId: 'sidebar.reports.ban_ip',
        type: 'item',
        url: '/ban-ip',
      },
      {
        id: 'blocked-domains',
        title: 'sidebar.reports.blocked.domains',
        messageId: 'sidebar.reports.blocked.domains',
        type: 'item',
        url: '/search/blocked-domains',
      }     
    ]
  },
  {
    id: 'config_auto_banning',
    title: 'sidebar.config_auto_banning',
    messageId: 'sidebar.config_auto_banning',
    icon: 'perm_data_setting',
    type: 'item',
    url: '/config_auto_banning',
  },
  {
    id: 'agents',
    title: 'sidebar.agents',
    messageId: 'sidebar.agents',
    icon: 'group',
    type: 'item',
    url: '/agents',
  },  
  {
    id: 'link-customer-id',
    title: 'sidebar.edit.linkCustomerId',
    messageId: 'sidebar.edit.linkCustomerId',
    type: 'item',
    icon: 'link',
    url: '/link-customer-id',
  },
  // {
  //   id: 'whats-new',
  //   title: 'sidebar.whats.new',
  //   messageId: 'sidebar.whats.new',
  //   type: 'item',
  //   icon: 'priority_high',
  //   url: '/whats-new',
  // },
  {
    id: 'main-site-banner-settings',
    title: 'sidebar.systemsitebanner',
    messageId: 'sidebar.systemsitebanner',
    type: 'item',
    count: 0,
    icon: 'settings',
    url: '/main-site-banner-settings',
  },
  // {
  //   id: 'support-ticket',
  //   title: 'sidebar.supportTicket',
  //   messageId: 'sidebar.supportTicket',
  //   type: 'item',
  //   count: 0,
  //   icon: 'help',
  //   url: '/support-ticket/',
  // }
  // {
  //   id: 'zeroactivity',
  //   title: 'sidebar.zeroactivity',
  //   messageId: 'sidebar.zeroactivity',
  //   type: 'item',
  //   count: 0,
  //   icon: 'rowing',
  //   url: '/zero_activity',
  // }
];
export default routesConfig;
